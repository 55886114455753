import {addressBlock} from "./heroArea.module.css";
import * as React from "react";
import footerImage from "../../images/BNW_logo_png.png";
import {firstGrid, phoneIcon} from "../../styles/main.module.css";
import {FiPhoneCall} from "react-icons/fi";
import {AiOutlineMail} from "react-icons/ai";
import {useState} from "react";

const Footer = () => {
    const [phoneNumber] = useState('+971549994155')
    const [phoneNumberWithoutPlus] = useState('971549994155')
    const [phoneNumberText] = useState('+971 54 999 4155')
    return (<>
        <div className={'bg-black'}>
            <div className={'container  py-12'}>

                <div className={`grid grid-cols-1  sm:grid-cols-1 md:grid-cols-2`}>
                    <div>
                        <img  src={footerImage}
                             alt=""/>
                    </div>
                    <div className={addressBlock}>
                        <p>
                            Unit No. 2107, The Prism Tower,
                            Al Mustaqbal Street, Business Bay,
                            Dubai, UAE
                        </p>
                        <p><AiOutlineMail className={`inline`}/> info@bnwdubai.com</p>

                        <p> <a className={'hover:text-hover-default'} href={`tel:${phoneNumber}`} rel="noopener">
                            <FiPhoneCall className={`${phoneIcon} inline`}/> {phoneNumberText}</a></p>
                    </div>


                </div>


            </div>

        </div>
    </>)
}

export default Footer
