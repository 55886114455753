import React from 'react';
const CentralParkDetails = () => {
    return (<>
        <div className=" central-park-details text-center pt-14 md:pt-8 md:flex md:justify-center md:items-center md:flex-col">
            <div className=' w-full'>
            <h1 className="overflow-y-hidden text-4xl font-bold w-4/5 m-auto pb-1 md:text-5xl">The Central Park</h1>
            <p className='w-4/5 m-auto font-medium md:text-xl'>Residential development is an inspired solution for a green and <br/>
            healthy lifestyle community within the heart of Dubai.</p>
            <div className='grid grid-cols-1 gap-3 m-auto justify-center items-center pt-11 pb-20 md:pb-8 md:grid-cols-4 md:pt-16 md:w-4/5'>
            <div className='pb-7'>
            <h1 className='overflow-y-hidden font-bold text-3xl md:text-5xl'>12</h1>
            <p className='text-sm font-medium md:text-lg'>Residential Buildings</p>
            </div>
            <div className='pb-7'>
            <h1 className='overflow-y-hidden font-bold text-3xl md:text-5xl'>20</h1>
            <p className='text-sm font-medium md:text-lg'>community Amenities</p>
            </div>
            <div className='pb-7'>
            <h1 className='overflow-y-hidden font-bold text-3xl md:text-5xl'>80</h1>
            <p className='text-sm font-medium md:text-lg'>80 Meters Wide Park</p>
            </div>
            <div className='pb-7'>
            <h1 className='overflow-y-hidden font-bold text-3xl md:text-5xl'>48,000</h1>
            <p className='text-sm font-medium md:text-lg'>SqM of Greenery</p>
            </div>
        </div>
        </div>
        </div>
        
       
    </>)
}

export default CentralParkDetails;