// extracted by mini-css-extract-plugin
export var topBarItem = "main-module--topBarItem--2sEg_";
export var topBarIcon = "main-module--topBarIcon--gREb_";
export var phoneIcon = "main-module--phoneIcon--2P2mH";
export var green = "main-module--green--1-obT";
export var aziziHeader = "main-module--aziziHeader--3yqrF";
export var buttonCss = "main-module--buttonCss--1Ra-g";
export var contentDiv = "main-module--contentDiv--1Cmvn";
export var headingText = "main-module--headingText--1jz4V";
export var paraText = "main-module--paraText--3-Zca";
export var firstGrid = "main-module--firstGrid--2qNqJ";
export var gridText = "main-module--gridText--3vxQm";
export var minClass = "main-module--minClass--EemSY";
export var minTextClass = "main-module--minTextClass--3OYT3";
export var eminityImageText = "main-module--eminityImageText--1-mzA";
export var iconLine = "main-module--iconLine--h8-pp";
export var footerMainSection = "main-module--footerMainSection--1C2Gh";
export var floatingBox = "main-module--floatingBox--6Zvq4";
export var input = "main-module--input--1T97B";
export var headerSection = "main-module--headerSection--wypiF";
export var footerList = "main-module--footerList--1MQ4p";
export var modal = "main-module--modal--3P-0M";
export var buttonCss1 = "main-module--buttonCss1--3UtHq";