import * as React from "react";
import FormComponent from "../formComponent";

const FormLayout = (props) => {
  return (
    <>
      <div className="text-center">
        <div className={`sm:px-2 md:max-w-sm m-auto`}>
          <div
            className={
              "text-center font-bold text-bold text-lg text-sm text-primary-gray mt-2 lg:block hidden"
            }
          >
            {/*Request an expert’s callback*/}
            <b>REGISTER YOUR INTEREST TODAY </b> <br />
            {/* <small className={"text-xs"}></small> */}
          </div>
          <div className={"pt-2 pb-2 md:block hidden "}>
            <span
              className={`block m-auto border-b-2 border-primary-gray w-1/4 md:w-32`}
            ></span>
          </div>
          {/*  <div className={'text-bold text-primary-darkGold text-l text-center text-sm  mt-2 lg:block hidden'}>
        <b>EXCLUSIVE DEALS WITH US</b>
        <br/>
        <small>Book Your Apartment with 10% only</small>
      </div>*/}
          <FormComponent
            formName={"crest"}
            mandatoryField={true}
            countryISO={props.countryISO}
            buttonText={"Register your interest"}
          />
          <div className={"text-center font-bold text-lg  lg:block hidden"}>
            BOOKINGS OPEN!
          </div>
          <div className={"text-center text-sm  lg:block hidden mb-2"}>
            {/* Book Your Apartment with 10% only*/}
            Book Now & Get Pre Launch Benefits
          </div>
        </div>
      </div>
    </>
  );
};

export default FormLayout;
