import * as React from "react";
import SimpleReactLightbox from "simple-react-lightbox";
import {} from "../components/sobhaVilla1Comp/heroArea.module.css";
import NavHeader from "../components/NavHeader";
import HeroArea from "../components/centralPark/heroArea";
// import Connectivity from "../components/newBeachFront/connectivities";
// import Eminity from "../components/newBeachFront/eminity";
// import Plan from "../components/newBeachFront/plan";
import Footer from "../components/centralPark/footer";
// import StickyForm from "../components/newBeachFront/stickyForm";
// import BeachHomeImage from "../images/newbeach/newbeachfrontimg.jpg";
// import PrivatePool from '../images/the-crest.png'
import { StaticImage } from "gatsby-plugin-image";
import Seo from "../components/App/SEO";
import { siteUrl } from "../components/_utils";
import { useEffect, useState } from "react";
import { getCountry } from "../action";
import { useScrollTracker } from "react-scroll-tracker";
import ReactGA from "react-ga";
import CentralParkDetails from "../components/centralPark/centralParkDetails";
import Connectivity from "../components/centralPark/connectivity";
import Amenities from "../components/centralPark/ameneties";
import FooterForm from "../components/centralPark/footerForm";
// import { contentDiv } from "../styles/sobha.module.css";
// import StickyBox from "react-sticky-box";
// import ModalPage from "../components/modalPage1";
const shortNumber = require("short-number");
let dollarAmount = 265450;
const NewBeachFront = ({ data }) => {
  const [countryISO, setCountryISO] = useState("ae");
  const [short, setShort] = useState(shortNumber(dollarAmount));
  const [symbol, setSymbol] = useState("$");
  const [downloadType, setDownloadFormType] = useState(null);
  useEffect(() => {
    getCountry({ amount: dollarAmount }).then((data) => {
      if (data.short) {
        setShort(data.short);
      }
      if (data.symbol) {
        setSymbol(data.symbol);
      }
      if (data.iso) {
        setCountryISO(data.iso.toLowerCase());
      }
    });
  }, []);
  if (typeof window !== "undefined") {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useScrollTracker([10, 15, 25, 50, 75, 100], ({ scrollY }) => {
      ReactGA.event({
        category: "scroll",
        action: scrollY,
      });
    });
  }
  const logoComp = (
    <StaticImage
      className="w-32 md:w-44 lg:w-46  align-middle"
      placeholder="tracedSVG"
      src={"../images/centralPark/logo.png"}
      alt={"Central Park"}
    />
    
  );

  const titleParams = {
    title: `Central Park | ${siteUrl}`,
    ogTitle: `Central Park | ${siteUrl}`,
    description:
      "Where Escaping is staying | Enjoy the perks of living by a crystal lagoon in central Dubai, Caribbean-style crystal lagoon facing residences",
    ogDescription:
      "Where Escaping is staying | Enjoy the perks of living by a crystal lagoon in central Dubai, Caribbean-style crystal lagoon facing residences",
    keywords: "5 YEAR PAYMENT PLAN",
    // secureImageUrl: `${PrivatePool}`,
    // ogImage: `${PrivatePool}`,
    ogImageAlt: "Central Park",
    orUrl: `${siteUrl}central-park/`,
    pageLink: `${siteUrl}central-park/`,
    canonicalRef: `${siteUrl}central-park/`,
  };
  return (
    <SimpleReactLightbox>
      <Seo data={titleParams} />
      <NavHeader logoComp={logoComp} />
      <HeroArea
        countryISO={countryISO}
        onFormOpen={(v) => {
          setDownloadFormType(v);
        }}
        short={short}
        symbol={symbol}
      />

    <CentralParkDetails/>
    <Connectivity/>
    <Amenities/>
      <FooterForm />
      <Footer/>
    </SimpleReactLightbox>
  );
};
export default NewBeachFront;
