import React from "react";

const HeroText = () => {
  return (
    <>
      <div className="centraltextBg">
        <h3 className="p-8">
          <span className="text-4xl">New Building </span><br/>Coming Soon...
        </h3>

        <div className="pt-4 font-bold text-primary-lightest text-4xl md:text-5xl leading-loose border-none">
        HEALTHY AND ACTIVE LIFESTYLE
        </div>
      </div>
    </>
  );
};
export default HeroText;
