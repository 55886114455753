// extracted by mini-css-extract-plugin
export var mainSection = "heroArea-module--mainSection--32Gna";
export var headerSection = "heroArea-module--headerSection--35UD2";
export var topSection = "heroArea-module--topSection--ze78_";
export var bottomUnderLine = "heroArea-module--bottomUnderLine--26472";
export var footerList = "heroArea-module--footerList--2_AVv";
export var heroText = "heroArea-module--heroText--KHz8I";
export var crestList = "heroArea-module--crestList--YlaAZ";
export var sobhaHeading = "heroArea-module--sobhaHeading--2RLA4";
export var sliderFooterContainer = "heroArea-module--sliderFooterContainer--2k-OJ";
export var sliderFooter = "heroArea-module--sliderFooter--2VU_3";
export var videoContainer = "heroArea-module--videoContainer--3Gi8h";
export var gridBox = "heroArea-module--gridBox--7xa3Q";
export var firstBlock = "heroArea-module--firstBlock--2WX1w";
export var gridBox2 = "heroArea-module--gridBox2--2aR28";
export var area = "heroArea-module--area--3VWzn";
export var price = "heroArea-module--price--3HE2c";
export var downloadBtn = "heroArea-module--downloadBtn--3E_io";
export var firstGrid = "heroArea-module--firstGrid--2d97c";
export var gridText = "heroArea-module--gridText--Fmna_";
export var minTextClass = "heroArea-module--minTextClass--1OIjK";
export var addressBlock = "heroArea-module--addressBlock--2xR8o";
export var formBox = "heroArea-module--formBox--1Yh_l";