import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { SRLWrapper } from "simple-react-lightbox";
const Amenities = () => {
    return (<>
    <div className="ameneties pt-10 pb-8 md:pb-14">
    <h1 className="text-center font-bold text-3xl  md:text-5xl">AMENETIES</h1>
        <div className="grid grid-cols-1 text-lg md:w-5/6 md:m-auto md:grid-cols-4 md:gap-6 font-semibold pt-4 md:pl-12">
            <div className="text-center m-auto pt-5">
                <StaticImage className="w-1/2" width={92} height={92} src="../../images/centralPark/anemeties/a1.png" alt="water fountain"/>
                <h2>Water <br/>Features</h2>
            </div>
            <div className="text-center m-auto pt-5">
                <StaticImage className="w-1/2" width={92} height={92} src="../../images/centralPark/anemeties/a2.png" alt="water fountain"/>
                <h2>Central <br/>Pool Area</h2>
            </div>
            <div className="text-center m-auto pt-5">
                <StaticImage className="w-1/2" width={92} height={92} src="../../images/centralPark/anemeties/a3.png" alt="water fountain"/>
                <h2>Outdoor <br/>Sport Facilities</h2>
            </div>
            <div className="text-center m-auto pt-5">
                <StaticImage className="w-1/2" width={92} height={92} src="../../images/centralPark/anemeties/a4.png" alt="water fountain"/>
                <h2>Children <br/>Playgrounds</h2>
            </div>
            <div className="text-center m-auto pt-5">
                <StaticImage className="w-1/2" width={92} height={92} src="../../images/centralPark/anemeties/a5.png" alt="water fountain"/>
                <h2>Jogging <br/>Tracks</h2>
            </div>
            <div className="text-center m-auto pt-5">
                <StaticImage className="w-1/2" width={92} height={92} src="../../images/centralPark/anemeties/a6.png" alt="water fountain"/>
                <h2>Outdoor <br/>Gym Facilities</h2>
            </div>
            <div className="text-center m-auto pt-5">
                <StaticImage className="w-1/2" width={92} height={92} src="../../images/centralPark/anemeties/a7.png" alt="water fountain"/>
                <h2>Main <br/>Event Spaces</h2>
            </div>
            <div className="text-center m-auto pt-5">
                <StaticImage className="w-1/2" width={92} height={92} src="../../images/centralPark/anemeties/a8.png" alt="water fountain"/>
                <h2>BBQ {"&"} Picnic <br/>Areas</h2>
            </div>
        </div>
        <div className="slideImages md:flex overflow-y-hidden ml-3 mr-3 mt-9">
            <SRLWrapper>
            <StaticImage className="rounded-md mb-3 md:mr-2 popupImages" src="../../images/centralPark/anemeties/slide/s1.jpeg" alt="Image sliding"/>
            <StaticImage className="popupImages rounded-md mb-3 md:mr-2" src="../../images/centralPark/anemeties/slide/s2.jpeg" alt="Image sliding"/>
            <StaticImage className="popupImages rounded-md mb-3 md:mr-2" src="../../images/centralPark/anemeties/slide/s3.jpeg" alt="Image sliding"/>
            <StaticImage className="popupImages rounded-md mb-3 md:mr-2" src="../../images/centralPark/anemeties/slide/s4.jpeg" alt="Image sliding"/>
            <StaticImage className="popupImages rounded-md mb-3 md:mr-2" src="../../images/centralPark/anemeties/slide/s5.jpeg" alt="Image sliding"/>
            <StaticImage className="popupImages rounded-md mb-3 md:mr-2" src="../../images/centralPark/anemeties/slide/s6.jpeg" alt="Image sliding"/>
            </SRLWrapper>
        </div>   
        </div>
        </>)
}

export default Amenities;