import React from "react";
import FormLayout from "./form";
const FooterForm = () => {
    return (<>
    <div className="footer-form">
        <div className="footerformlayout p-5 ">
        <FormLayout />
        </div>
    </div>
    </>)
}
export default FooterForm;