import React from 'react';

const Connectivity = () => {
    return (<>
    
    <div className="connectivity text-center relative pb-8">
    {/* <div className='wave-border'> */}
      
        {/* </div> */}
        <div className='w-full pt-20'>
        <h1 className='text-3xl font-bold'>CONNNECTIVITY</h1>
        <p className='font-medium text-white text-sm w-11/12 m-auto pt-4 md:text-lg md:w-5/6'>Connected by a well-established road network, and located between Al Wasl Road and Sheikh Zayed Road, Central Park at City Walk is ideally placed for quick and convenient access to major landmarks in Dubai.</p>
        <div className='pt-8 text-4xl grid grid-cols-1 md:grid-cols-4 md:gap-y-11 md:w-5/6 md:m-auto md:text-5xl'>
            <div className=' pb-4 '>
            <div className='flex justify-center font-bold'>
                <h1 className='overflow-y-hidden'>1</h1>
                <h3 className='text-sm font-medium'>Minute from</h3>
            </div>
            <h1 className='text-white text-lg'>City Walk Mall</h1>
            </div>
            <div className=' pb-4 '>
            <div className='flex justify-center font-bold'>
                <h1 className='overflow-y-hidden'>5</h1>
                <h3 className='text-sm font-medium'>Minute from</h3>
            </div>
            <h1 className='text-white text-lg'>Coca-Cola Arena</h1>
            </div>
            <div className=' pb-4 '>
            <div className='flex justify-center font-bold'>
                <h1 className='overflow-y-hidden'>10</h1>
                <h3 className='text-sm font-medium'>Minute from</h3>
            </div>
            <h1 className='text-white text-lg'>Jumeirah Beach</h1>
            </div>
            <div className=' pb-4 '>
            <div className='flex justify-center font-bold'>
                <h1 className='overflow-y-hidden'>15</h1>
                <h3 className='text-sm font-medium'>Minute from</h3>
            </div>
            <h1 className='text-white text-lg'>Burj Al Arb</h1>
            </div>
            <div className=' pb-4 '>
            <div className='flex justify-center font-bold'>
                <h1 className='overflow-y-hidden'>15</h1>
                <h3 className='text-sm font-medium'>Minute from</h3>
            </div>
            <h1 className='text-white text-lg'>Dubai Int Airport</h1>
            </div>
            <div className=' pb-4 '>
            <div className='flex justify-center font-bold'>
                <h1 className='overflow-y-hidden'>15</h1>
                <h3 className='text-sm font-medium'>Minute from</h3>
            </div>
            <h1 className='text-white text-lg'>La Mer Beach</h1>
            </div>
            <div className=' pb-4 '>
            <div className='flex justify-center font-bold'>
                <h1 className='overflow-y-hidden'>20</h1>
                <h3 className='text-sm font-medium'>Minute from</h3>
            </div>
            <h1 className='text-white text-lg'>Palm Jumeirah</h1>
            </div>
            <div className=' pb-4 '>
            <div className='flex justify-center font-bold'>
                <h1 className='overflow-y-hidden'>20</h1>
                <h3 className='text-sm font-medium'>Minute from</h3>
            </div>
            <h1 className='text-white text-lg'>Dubai Marina</h1>
            </div>
            
        </div>
        
        </div>
        <iframe className='mb-8 mt-8 md:w-4/5 rounded-md w-11/12 min-h-full md:h-max m-auto bg-gray-500' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d57771.233505680335!2d55.26342!3d25.179535!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe5ba956d732c61e0!2sCentral%20Park%20At%20City%20Walk%20By%20Meraas!5e0!3m2!1sen!2sus!4v1649924762306!5m2!1sen!2sus" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        <div className='wave-border-second'>

    </div>
    </div>

    
    </>)
}
export default Connectivity;