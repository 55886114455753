import {
    floatingBox,
    input,
} from '../components/aziziComp/form.module.css'
import * as React from 'react'
// import ReactFlag from "../reactFlagsComp"
import {useForm} from 'react-hook-form'
import {addForm, apiUrl} from '../action'
import {siteUrl} from './_utils'
import {loaderText, telinputContainer} from '../components/fiveJBR/form.module.css'
import ReactFlag from './reactFlagsComp-back'
import {ImSpinner} from 'react-icons/im'
import {StaticImage} from 'gatsby-plugin-image'
import ReactGA from 'react-ga'

const FormLayout = (props) => {
    const {register, handleSubmit, formState: {errors}} = useForm()
    let [mobile, setMobile] = React.useState('')
    let [phoneCode, setCode] = React.useState('')
    const [loading, setLoading] = React.useState(false)

    const onSubmit = async (data) => {

        // console.log(data)

        if (!data.name) {
            alert('Enter Name')
            return false
        }
        if (!mobile) {
            alert('Fill mobile')
            return false
        }

        if (!phoneCode) {
            alert('Choose Phone Code')
            return false
        }
        if (!data.email) {
            alert('Fill Email Id')
            return false
        }

        /*  if (props.mandatoryField) {
              if (!mobile || !phoneCode || !data.email) {
                  alert('Fill required Fields')
                  return
              }
          }*/

        ReactGA.event({
            category: 'BtnClick',
            action: 'sliderButtonClick',
        })


        let objj = {...data, form: props.formName, phoneCode, mobile: mobile}
        if (props.formType) {
            objj.formType = props.formType
        }
        if (props.downloadType) {
            objj.downloadType = props.downloadType
        }
        if (props.brochureType) {
            objj.brochureType = props.brochureType
        }


        setLoading(true)

        let x = await addForm(objj)


        window.gtag('event', 'click', {send_to: ['AW-368264267/97v8CICmr4wDEMuIza8B']})
        // window.gtag("event", "click", { send_to: "AW-CONVERSION_ID", ...data })

        setLoading(false)


        if (!x.error) {
            if (x.downloadId) {

                let downloadLink = `${apiUrl}download?sid=${x.downloadId}`;

                let link = document.createElement('a')
                document.body.appendChild(link)
                link.setAttribute('type', 'hidden')
                link.href = downloadLink;
                //link.download = `${props.downloadType}.pdf`
                link.click()
                document.body.removeChild(link)
                document.getElementById('downloadbrochure').style.display = 'none';
            } else {

                setTimeout(() => {
                    window.location.href = `${siteUrl}thankuPage`
                }, 1000)
            }

        } else {


        }

    }

    return (<div className={`sm:px-2 md:max-w-sm m-auto`}>

            <form className={'px-6 sm:px-0'} onSubmit={handleSubmit(onSubmit)}>

                <div className={'my-4'}>
                    <input
                        className={`w-full border border-primary-gray border-opacity-20 py-2 px-4 text-primary-gray text-sm `}
                        type="text" placeholder="Name" id={'name'} {...register('name')} />
                </div>

                <div className={'flex my-4'}>
                    <div className={`border border-primary-gray border-opacity-20 ${telinputContainer}`}>
                        <ReactFlag countryISO={props.countryISO} footerFlag={false} flagValue={(value) => {
                            if (value && value.mobile) {
                                setMobile(value.mobile)
                                setCode(value.code)
                            } else {
                                setMobile('')
                                setCode('')
                            }
                        }}/>
                    </div>

                </div>

                <div className={'my-4'}>
                    <input
                        className={`w-full border border-primary-gray border-opacity-20 py-2 px-4 text-primary-gray text-sm `}
                        type="email" placeholder="Email" id={'email'} {...register('email')} />
                </div>

                <div className={'my-4 text-center'}>
                    <button disabled={loading}
                            style={{flex: 1, alignContent: 'center'}}
                            className={'rounded text-lg hover:bg-primary-gray bg-primary-darkGreen py-3 px-4 text-white'}
                            type="submit">
                        {loading ? <span className={loaderText}><ImSpinner className={'icon-spin '}/></span> : null}
                        <span>{props.buttonText ? props.buttonText : 'Submit'}</span>
                    </button>
                    <div className={'mt-1'}>
                        <a href={'https://bnwdubai.com/privacy-policy/'}
                           target={'_blank'}
                           className={'text-xs text-blue-400 border-b border-blue-400'}>Privacy Policy</a>
                    </div>


                </div>

            </form>

        </div>
    )
}

export default FormLayout
